import axios, { AxiosRequestConfig } from 'axios';
import { getCoreConfig } from '@/common/config';
import { environment } from '@/common/utils';
import { httpClientAgentConfig } from './httpAgent';

/**
 * For the non production environment we concat a string
 * which bypasses the CORS of the API gateway
 *
 * @param config the axios request config object
 * @returns the new axios request config with force cors parameter
 */
export const corsRequestConfig = (config: AxiosRequestConfig) => {
  const newConfig = { ...config };

  /**
   * When the request is executed on server-side we can't includes the force-cors parameter
   * because the API gateway returns status code 400
   */

  if (environment.isDevEnv && environment.isClientSide()) {
    const initialCaracter = newConfig.url?.includes('?') ? '&' : '?';

    newConfig.url += `${initialCaracter}force-cors=-zY8TnWPyUU6nvQj2ABKz^3K%A@*NkbM!Q6Y83^bh9TTnHL5t@mam!$BUTn=eaZ_`;
  }

  return newConfig;
};

const { publicRuntimeConfig } = getCoreConfig();

export const client = axios.create({
  baseURL: publicRuntimeConfig.publicApi.base,
  ...httpClientAgentConfig,
});

// if (environment.isProdReleaseEnv) {
//   client.defaults.withCredentials = true;
// }

// USE THIS FOR SCOOBY
// client.interceptors.request.use(
//   function (config) {
//     const coreHeaders = getClientHeaders();

//     const headers = {
//       ...config.headers,
//       ...coreHeaders,
//     };

//     config.headers = headers;

//     const newConfig = corsRequestConfig(config);
//     return newConfig;
//   },
//   function (error) {
//     return Promise.reject(error);
//   },
// );
