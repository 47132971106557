import { Typography } from '@grupo-sbf/motriz-core';
import { Loading } from '@grupo-sbf/motriz-icons';
import { hexToRGBA, media } from '@grupo-sbf/motriz-utils';
import styled, { AnyStyledComponent, css } from 'styled-components';

type DirectionType = {
  direction?: 'column' | 'row';
};

type LoaderProps = DirectionType & {
  isFullScreen?: boolean;
  isFullHeight?: boolean;
};

type BoxLoaderProps = DirectionType & {
  isFullHeight?: boolean;
};

type CustomLoaderProps = DirectionType & {
  titleAlly?: string;
  text?: string;
  typographyVariant?: string;
  typographySize?: string;
};

type StyledTypographyType = DirectionType;

const PageLoaderContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: ${theme.spacing[1000]};
    left: 0;
    bottom: 0;
    right: 0;
    z-index: ${theme.zIndex[300]};
    background: ${hexToRGBA(theme.color.neutral[100], theme.opacity[600])};

    ${media.greaterThan('large')} {
      top: ${theme.spacing[1200]};
    }
  `}
`;

const ComponentLoaderContainer = styled.div<LoaderProps>`
  ${({ isFullHeight }) => css`
    height: ${isFullHeight ? '100vh' : 'auto'};
    min-height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
  `}
`;

const BoxLoader = styled.div<BoxLoaderProps>`
  ${({ theme, direction = 'column' }) => css`
    display: flex;
    align-items: center;
    color: ${theme.color.neutral[700]};
    position: relative;

    ${direction === 'column'
      ? `
      flex-direction: column;
      top: -15%;
    `
      : `
      flex-direction: row;
      justify-content: center;
    `}
  `}
`;

const LoadingStyled = styled(Loading as unknown as AnyStyledComponent)`
  will-change: transform;

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  animation: rotation 1.5s infinite linear;
`;

const LoadingTypographyStyled = styled(Typography)<StyledTypographyType>`
  ${({ theme, direction = 'column' }) => css`
    color: ${theme.color.neutral[700]};

    ${direction === 'column' &&
    `
      padding-top: ${theme.spacing[200]};

      ${media.greaterThan('large')} {
        padding-top: ${theme.spacing[400]};
      }
    `}
  `}
`;

export const CustomLoader = ({
  titleAlly = 'carregando o conteúdo',
  text = 'Carregando',
  typographyVariant = 'title',
  typographySize = 'h1',
  direction,
}: CustomLoaderProps) => {
  return (
    <BoxLoader direction={direction}>
      <LoadingStyled titleAccess={titleAlly} />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <LoadingTypographyStyled
        direction={direction}
        type={typographyVariant}
        as={typographySize}
      >
        {text}
      </LoadingTypographyStyled>
    </BoxLoader>
  );
};

const Loader = ({ isFullScreen = true, isFullHeight = true }: LoaderProps) => {
  const ComponentLoader = () => (
    <ComponentLoaderContainer isFullHeight={isFullHeight}>
      <CustomLoader />
    </ComponentLoaderContainer>
  );

  const PageLoader = () => (
    <PageLoaderContainer>
      <CustomLoader />
    </PageLoaderContainer>
  );

  const Container = isFullScreen ? PageLoader : ComponentLoader;

  return <Container />;
};

export default Loader;
