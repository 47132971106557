const LOGIN_ROUTE = '/login';
const LOGOUT_ROUTE = '/logout';
const SIGNUP_ROUTE = '/cadastro';
const HOME = '/';
const SALES = '/minhas-vendas/vendas';
const LINKS = '/links';
const SHOWCASE_EDIT = '/vitrine/update';
const PROFILE = '/perfil';
const PRE_REGISTER = '/pre-cadastro';
const TRAININGS = '/treinamentos';
const NETWORK_ACCOUNTS = '/redes-sociais';
const NOTIFICATIONS = '/notificacoes';
const ONBOARDING = '/onboarding';
const PLACE_YOUR_ORDER = '/realizar-pedido';
const RECEIVED_REQUEST = '/pedido-recebido';
const PENDING_REGISTRATION = '/registro-pendente';
const PENDING_APPROVE_PRE_REGISTRATION = '/aprovacao-pendente';
const WAITING_PRE_REGISTRATION = '/analise-pendente';
const DENIED_PRE_REGISTRATION = '/reprovado';
const ACTIVE_REGISTRATION = '/cadastro-ativo';
const SIGNUP_FINISHED = '/finalizado';
const COMMISSION = '/minhas-vendas/comissao';
const MY_SALES = '/minhas-vendas';

const ERROR_404 = '/404';

/**
 * This function is used to direct the route to somewhere.
 * For example, home link.
 * @returns {Object} routes - Core routes.
 */
export const getCoreRoutes = () => ({
  login: LOGIN_ROUTE,
  logout: LOGOUT_ROUTE,
  signup: SIGNUP_ROUTE,
  home: HOME,
  sales: SALES,
  commission: COMMISSION,
  mySales: MY_SALES,
  links: LINKS,
  showcaseEdit: SHOWCASE_EDIT,
  profile: PROFILE,
  preRegister: PRE_REGISTER,
  trainings: TRAININGS,
  networkAccounts: NETWORK_ACCOUNTS,
  notifications: NOTIFICATIONS,
  onboarding: ONBOARDING,
  placeYourOrder: PLACE_YOUR_ORDER,
  receivedRequest: RECEIVED_REQUEST,
  pendingRegistration: PENDING_REGISTRATION,
  pendingApprovePreRegistration: PENDING_APPROVE_PRE_REGISTRATION,
  preRegistrationWaiting: WAITING_PRE_REGISTRATION,
  preRegistrationDenied: DENIED_PRE_REGISTRATION,
  activeRegistration: ACTIVE_REGISTRATION,
  signupFinished: SIGNUP_FINISHED,
});

export const unprotectedRoutes = [
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  SIGNUP_ROUTE,
  PRE_REGISTER,
  ERROR_404,
  SIGNUP_FINISHED,
];

export const PUBLIC_IMAGES_PATH = '/images';
