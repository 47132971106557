import { getCorePaths } from '@/common/config';
import { client } from '@/common/services/api';
import { InfluencerLoginData } from './types';

const { influencer } = getCorePaths();

const apiUrl = influencer.login;

export const getInfluencerLoginData = async (token: string) => {
  const url = `${apiUrl}`;
  const response = await client.get<InfluencerLoginData>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};
