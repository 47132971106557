import { useRouter } from 'next/router';
import { signIn as nextAuthSignIn, useSession } from 'next-auth/react';
import { useEffect, useMemo } from 'react';
import { Loader } from '@/common/components/Loader';
import { getCoreRoutes } from '@/common/config';
import { useAuth } from '@/common/contexts/AuthContext';
import { KeycloakErrors } from '@/common/utils/auth/contants';
import { getInfluencerLoginData } from '@/modules/influencer/services';
import { handleXClientTokenCookie } from '../common/utils/auth';

const { home, login, logout, onboarding } = getCoreRoutes();

function Login() {
  const { push } = useRouter();
  const { data: session, status } = useSession();
  const { signIn } = useAuth();

  const isLoading = useMemo(() => status === 'loading', [status]);
  const isAuthenticated = useMemo(() => status === 'authenticated', [status]);
  const isUnauthenticated = useMemo(
    () => status === 'unauthenticated',
    [status],
  );

  useEffect(() => {
    const handleSignIn = async () => {
      await nextAuthSignIn('sbf');
    };

    const handleLogout = async () => {
      await push(logout);
    };

    const handleAuthenticated = async () => {
      try {
        const influencer = await getInfluencerLoginData(
          session?.accessToken as string,
        );

        if (!influencer) {
          await push(login);
          return;
        }

        const { user, token } = handleXClientTokenCookie(session, influencer);
        signIn(user, token);

        if (!user.onboardViewed) {
          await push(onboarding);
          return;
        }

        await push(home);
      } catch {
        handleLogout();
      }
    };

    if (
      session &&
      isAuthenticated &&
      session.error !== KeycloakErrors.refreshToken
    )
      handleAuthenticated();

    if (
      session &&
      isAuthenticated &&
      session.error === KeycloakErrors.refreshToken
    )
      handleLogout();

    if (!session && isUnauthenticated) handleSignIn();
  }, [push, signIn, session, isAuthenticated, isUnauthenticated]);

  if (isLoading) {
    return <Loader isFullScreen />;
  }
  return;
}

export default Login;
