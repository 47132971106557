export type PreRegistrationPathsKeyType =
  | 'base'
  | 'create'
  | 'checkCpf'
  | 'inviteBu'
  | 'sendPreRegistrationEmail'
  | 'addNewPreRegistrationBu';
export type PreRegistrationPathsType = Record<
  PreRegistrationPathsKeyType,
  string
>;

export type InfluencerPathsKeyType =
  | 'login'
  | 'signupValidation'
  | 'create'
  | 'update'
  | 'editProfile'
  | 'createClothesSize'
  | 'onboardingFlag'
  | 'updateReceipt'
  | 'getProfile'
  | 'createAddress'
  | 'updateAddress'
  | 'bankProofAuthenticated'
  | 'companyCreate';

export type InfluencerPathsType = Record<InfluencerPathsKeyType, string>;
export type MetricsPathsKeyType =
  | 'indicatorsMonthly'
  | 'indicatorMetricsList'
  | 'indicatorsDaily'
  | 'metricsDaily';

export type MetricsPathsType = Record<MetricsPathsKeyType, string>;

export type TeamsPathsKeyType = 'listMembers' | 'socialComparison';
export type TeamsPathsType = Record<TeamsPathsKeyType, string>;

export type LinksPathsKeyType = 'parameterizedLink' | 'linkList';
export type LinksPathsType = Record<LinksPathsKeyType, string>;

export type SalesPathsKeyType =
  | 'monthlyReport'
  | 'ordersReport'
  | 'categoriesReport'
  | 'productsReport'
  | 'formatSalesMonthly';
export type SalesPathsType = Record<SalesPathsKeyType, string>;

export type CommissionPathsKeyType = 'monthlyReport' | 'uploadInvoice';

export type CommissionPathsType = Record<CommissionPathsKeyType, string>;

export type TrainingsPathsKeyType = 'trainingsList';
export type TrainingsPathsType = Record<TrainingsPathsKeyType, string>;

export type NetorkAccountsPathsKeyType = 'list' | 'sync';
export type NetorkAccountsPathsType = Record<
  NetorkAccountsPathsKeyType,
  string
>;

export type AddressPathsKeyType = 'getByCep' | 'getByCepAuth';
export type AddressPathsType = Record<AddressPathsKeyType, string>;

export type NotificationsPathsKeyType = 'list' | 'updateViewed';
export type NotificationsPathsType = Record<NotificationsPathsKeyType, string>;

export type BankAccountPathsKeyType = 'create';
export type BankAccountPathsType = Record<BankAccountPathsKeyType, string>;

const ADDRESS_GET_BY_CEP_PATH = '/v1/address/signup/';
const ADDRESS_GET_BY_CEP_AUTH_PATH = '/v1/address/authenticated';
const PRE_REGISTRATION_BASE_API_PATH = '/v2/pre-registration';
const PRE_REGISTRATION_CREATE_API_PATH = `${PRE_REGISTRATION_BASE_API_PATH}/create`;
const PRE_REGISTRATION_CHECK_CPF_API_PATH = '/v1/pre-registration/validate';
const PRE_REGISTRATION_INVITE_BU = '/v1/influencers/request-bu';
const PRE_REGISTRATION_EMAIL_API_PATH =
  '/v1/pre-registration/pre-registration-bu/email';
const PRE_REGISTRATION_ADD_BU =
  '/v2/pre-registration/pre-registration-bu/create';

const INFLUENCER_LOGIN_API_PATH = '/v1/influencers/login-data';
const INFLUENCER_SIGNUP_VALIDATION = '/v1/influencers/validation/';
const INFLUENCER_CREATE = '/v2/influencers/create';
const INFLUENCER_UPDATE = '/v1/influencers';
const INFLUENCER_EDIT_PROFILE = '/v1/influencers/edit-profile';
const INFLUENCER_CLOTHES_SIZE_CREATE = '/v1/influencers/clothesSize/create';
const INFLUENCER_ONBOARDING_FLAG = '/v1/influencers/onboardViewed';
const INFLUENCER_UPDATE_RECEIPT = '/v1/influencers/bank-infos/upload';
const INFLUENCER_GET_PROFILE = '/v1/influencers/profile';
const INFLUENCER_CREATE_ADDRESS = '/v1/influencers/address/create';
const INFLUENCER_UPDATE_ADDRESS = '/v1/influencers/address/update';
const INFLUENCER_BANK_PROOF_AUTH =
  '/v1/influencers/bank-infos/authenticated/upload';
const INFLUENCER_COMPANY_CREATE = '/v1/influencers/company/create';

const BANK_ACCOUNT_CREATE_PATH = '/v1/bank-account/create';

const SALES_MONTHLY = '/v1/sales/report/monthly';
const SALES_ORDERS_MONTHLY = '/v1/sales/orders/monthly';
const SALES_CATEGORIES_MONTHLY = '/v1/sales/categories/monthly';
const SALES_PRODUCTS_MONTHLY = '/v1/sales/products/monthly';
const FORMAT_SALES_MONTHLY = '/v1/sales/format-sales/monthly';
const METRICS_INDICATORS_MONTHLY = '/v1/metrics/indicators/monthly';
const INDICATOR_METRICS_LIST = '/v1/metrics/indicator/';

const COMMISSION_MONTHLY = '/v1/influencers/commissions/list';
const UPLOAD_INVOICE = '/v1/influencers/commissions/invoice/upload';

const INDICATORS_DAILY = '/v1/metrics/indicators/daily';
const METRICS_DAILY = '/v1/metrics/daily/';

const TEAM_LIST_MEMBERS = '/v1/teams/members';
const TEAM_SOCIAL_COMPARISON = '/v1/teams/social-comparison';

const PARAMETERIZED_LINK = '/v1/link';
const LINK_LIST = '/v1/link/list';

const TRAININGS_LIST = 'v1/trainings/list';

const NETWORK_ACCOUNTS = '/v1/network-accounts';
const SYNC_NETWORK_ACCOUNTS = '/v1/network-accounts/sync';

const NOTIFICATIONS_LIST_PATH = '/v1/notifications/list';
const NOTIFICATIONS_UPDATE_VIEWED_PATH = '/v1/notifications/update-viewed';

type CorePathsType = {
  preRegistration: PreRegistrationPathsType;
  influencer: InfluencerPathsType;
  metrics: MetricsPathsType;
  teams: TeamsPathsType;
  links: LinksPathsType;
  sales: SalesPathsType;
  commission: CommissionPathsType;
  trainings: TrainingsPathsType;
  networkAccounts: NetorkAccountsPathsType;
  address: AddressPathsType;
  notification: NotificationsPathsType;
  bankAccount: BankAccountPathsType;
};

export const getCorePaths = (): CorePathsType => ({
  preRegistration: {
    base: PRE_REGISTRATION_BASE_API_PATH,
    create: PRE_REGISTRATION_CREATE_API_PATH,
    checkCpf: PRE_REGISTRATION_CHECK_CPF_API_PATH,
    inviteBu: PRE_REGISTRATION_INVITE_BU,
    sendPreRegistrationEmail: PRE_REGISTRATION_EMAIL_API_PATH,
    addNewPreRegistrationBu: PRE_REGISTRATION_ADD_BU,
  },
  influencer: {
    login: INFLUENCER_LOGIN_API_PATH,
    signupValidation: INFLUENCER_SIGNUP_VALIDATION,
    create: INFLUENCER_CREATE,
    update: INFLUENCER_UPDATE,
    editProfile: INFLUENCER_EDIT_PROFILE,
    createClothesSize: INFLUENCER_CLOTHES_SIZE_CREATE,
    onboardingFlag: INFLUENCER_ONBOARDING_FLAG,
    updateReceipt: INFLUENCER_UPDATE_RECEIPT,
    getProfile: INFLUENCER_GET_PROFILE,
    createAddress: INFLUENCER_CREATE_ADDRESS,
    updateAddress: INFLUENCER_UPDATE_ADDRESS,
    bankProofAuthenticated: INFLUENCER_BANK_PROOF_AUTH,
    companyCreate: INFLUENCER_COMPANY_CREATE,
  },
  metrics: {
    indicatorsMonthly: METRICS_INDICATORS_MONTHLY,
    indicatorMetricsList: INDICATOR_METRICS_LIST,
    indicatorsDaily: INDICATORS_DAILY,
    metricsDaily: METRICS_DAILY,
  },
  sales: {
    monthlyReport: SALES_MONTHLY,
    ordersReport: SALES_ORDERS_MONTHLY,
    categoriesReport: SALES_CATEGORIES_MONTHLY,
    productsReport: SALES_PRODUCTS_MONTHLY,
    formatSalesMonthly: FORMAT_SALES_MONTHLY,
  },
  commission: {
    monthlyReport: COMMISSION_MONTHLY,
    uploadInvoice: UPLOAD_INVOICE,
  },
  teams: {
    listMembers: TEAM_LIST_MEMBERS,
    socialComparison: TEAM_SOCIAL_COMPARISON,
  },
  links: {
    parameterizedLink: PARAMETERIZED_LINK,
    linkList: LINK_LIST,
  },
  trainings: {
    trainingsList: TRAININGS_LIST,
  },
  networkAccounts: {
    list: NETWORK_ACCOUNTS,
    sync: SYNC_NETWORK_ACCOUNTS,
  },
  address: {
    getByCep: ADDRESS_GET_BY_CEP_PATH,
    getByCepAuth: ADDRESS_GET_BY_CEP_AUTH_PATH,
  },
  notification: {
    list: NOTIFICATIONS_LIST_PATH,
    updateViewed: NOTIFICATIONS_UPDATE_VIEWED_PATH,
  },
  bankAccount: {
    create: BANK_ACCOUNT_CREATE_PATH,
  },
});
