import { ParsedUrlQuery } from 'node:querystring';
import { Session } from 'next-auth';
import { setCookie } from 'nookies';
import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_CLIENT_TOKEN,
  COOKIE_REFRESH_TOKEN,
} from '@/common/config';
import { CurrentUser } from '@/common/types';
import { dataLayer } from '@/common/utils/';
import { InfluencerLoginData } from '@/modules/influencer/services/Influencer/types';

export const setSessionCookies = (session: Session) => {
  setCookie(undefined, COOKIE_CLIENT_TOKEN, session.x_client_token as string);
  setCookie(undefined, COOKIE_ACCESS_TOKEN, session.accessToken as string);
  setCookie(undefined, COOKIE_REFRESH_TOKEN, session.refreshToken as string);
};

export const handleXClientTokenCookie = (
  session: Session | null,
  influencer: InfluencerLoginData,
): { user: CurrentUser; token: string } => {
  if (session && session.token && influencer) {
    const user = influencer;
    setSessionCookies(session);
    dataLayer.push({
      event: 'login',
      event_type: 'click',
      method: 'keycloak',
      category: 'UI - Login',
      action: 'sc_session_start',
      label: 'Signin with sucess',
      id: influencer.id,
      origin,
    });

    return { user, token: session.accessToken as string };
  }

  return { user: {} as CurrentUser, token: '' };
};

export const generateSignInCallbackUrl = (
  query: ParsedUrlQuery,
  defaultRoute = '/',
) => {
  const { redirect } = query;

  if (!redirect) {
    return defaultRoute;
  }

  let additionalParams = '';

  Object.keys(query).map((key) => {
    if (key !== 'redirect') {
      const value = query[key];
      additionalParams += `&${key}=${value}`;
    }
  });

  const url = `${decodeURI(redirect as string)}${additionalParams}`;

  return url;
};
