import Agent from 'agentkeepalive';

/**
 * Agent config
 */
const agentConfig = {
  keepAlive: true,
  keepAliveMsecs: 2000,
  maxFreeSockets: 128,
  socketActiveTTL: 300_000,
  timeout: 60_000,
  freeSocketTimeout: 30_000,
};

const httpsAgentConfig = new Agent.HttpsAgent(agentConfig);
const httpAgentConfig = new Agent(agentConfig);

export const httpClientAgentConfig = {
  httpsAgent: httpsAgentConfig,
  httpAgent: httpAgentConfig,
  timeout: 50_000,
};
